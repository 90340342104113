const types = [ 
	'amusement_park',
	'aquarium',
	'art_gallery', 
	'bar', 
	'bowling_alley', 
	'cafe', 
	'church', 
	'mosque', 
	'museum',
	'night_club', 
	'park', 
	'restaurant', 
	'tourist_attraction', 
	// 'point_of_interest'
];

const typesObj = types.reduce((a,b)=> (a[b]='',a),{});

export default typesObj
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import Button from '@material-ui/core/Button';  
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box'; 
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { spacing } from '@material-ui/system';
import Container from '@material-ui/core/Container';

const text = {
  'default': {
    zIndex: 10,
    position: 'absolute',
    textAlign: 'center', 
    bottom: 0
  },
  'wedwedgo.com': {
    zIndex: 10,
    position: 'absolute',
    textAlign: 'center', 
    bottom: 0, 
  }
}

const cardMediaOverlay = {
  'default': { 
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    height: 110,
    justifyContent: 'center',
    display: 'flex'
  },
  'wedwedgo.com': {
    background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    height: 110,
    justifyContent: 'center',
    display: 'flex'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'flex-end',
    display: 'flex', 
    paddingBottom: 16 
  },
  mediaXs: {  
    width: '100%',
    height: '300px',
    zIndex: 2
  },
  mediaSm: {  
    width: '100%',
    height: '320px',
    zIndex: 2
  },
  mediaLg: {  
    width: '100%',
    height: '400px',
    zIndex: 2
  },
  text: text[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? text[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : text['default'],
  titleXs: { 
    marginTop: '35px',
    fontWeight: 500,
    fontSize: '1.75rem',
    marginBottom: 0,
    transform: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "translateY(-50px)" : "inherit",
    color: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "white" : "inherit"  
  }, 
  titleSm: { 
    marginTop: '100px',
    fontWeight: 500,
    fontSize: '2.75rem',
    marginBottom: 0, 
    transform: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "translateY(-50px)" : "inherit",
    color: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "white" : "inherit"
  }, 
  titleLg: { 
    marginTop: '100px',
    fontWeight: 500,
    fontSize: '3.75rem',
    marginBottom: 0,
    transform: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "translateY(-50px)" : "inherit",
    color: typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] === 'elopifi.com'
      ? "white" : "inherit"
  },  
  subtitleXs: { 
    letterSpacing: '0.2px',
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: 0
  },
  subtitleSm: { 
    letterSpacing: '0.2px',
    fontSize: '18px',
    lineHeight: '26px',
    marginBottom: 0
  },
  cardMediaOverlay: cardMediaOverlay[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? cardMediaOverlay[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : cardMediaOverlay['default'], 
})); 

export default function Hero({params}) {  
  const classes = useStyles();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  // console.log('theme ', theme)
  return (
    <div className={classes.root}>
      <CardMedia
        className={matchesLg ? classes.mediaLg : matchesSm ? classes.mediaSm : classes.mediaXs}
        image={params.src} 
      /> 
      <div className={classes.cardMediaOverlay}>
      <Container className={classes.text} maxWidth="md">  
        <Typography className={matchesLg ? classes.titleLg : matchesSm ? classes.titleSm : classes.titleXs} variant={params.variant} component={params.component} gutterBottom>
          {params.title}
        </Typography> 
        {params.subtitle ? <Box className={matchesSm ? classes.subtitleSm : classes.subtitleXs} component="p">
          {params.subtitle}
        </Box> : null}
      </Container>
      </div>
    </div>
  );
  
}
